<template>
  <div class="container h-100">
    <!-- <div>all</div> -->
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-lg-10">
        <div class="card card-custom">
          <div class="pt-5 text-center">
            <!-- <Logo></Logo> -->
            <h5 class="text-muted">Terms and Conditions</h5>
          </div>

          <!-- <form onS></form> -->
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div style="height: 90vh">
                  <object
                    :data="`${terms.default}#toolbar=0&navpanes=0&scrollbar=0`"
                    width="100%"
                    height="100%"
                    class="py-3"
                  ></object>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-end">
            <router-link to="/" type="button" class="btn btn-s2s-blue">
              Back to Home
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Logo from "@/components/Logo.vue";
// import axios from "axios";
export default {
  components: {
    // Logo,
  },

  data() {
    return {};
  },

  methods: {
    goBack() {
      this.$router.push({ path: "remaining-time" });
    },
  },

  created() {
    // this.$swal(
    //   "Success",
    //   `Thank you for your payment, you can now enjoy your ${this.getLoad.description}`,
    //   "success"
    // ).then(() => {
    //   this.goBack();
    // });
  },

  computed: {
    terms() {
      return require("../assets/pdf/tnc_new.pdf");
    },
  },
};
</script>
